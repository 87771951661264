import { useQuery } from 'react-query'
import moment from 'moment';

export function useReservationsQuery() {

    return useQuery("bookings",
        async () => {
            const response = await fetch("/bookings.php", {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            if (!response.ok) {
                throw new Error('Network response was not ok')
            }

            const bookings = await response.json();

            return bookings.filter(event => !event.deleted).map(event => {
                return {
                    start: moment(event.start).toDate(),
                    end: moment(event.finish).toDate(),
                    title: event.fullName,
                    resourceId: event.resourceId,
                    resName: event.resName,
                    type: event.field1R
                }
            })
        }
        , {
            placeholderData: [],
            staleTime: 45000,
            refetchInterval: 60000,
        })

}

export function useResourcesQuery() {

    return useQuery("resources",
        async () => {
            const response = await fetch("/resources.php", {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            if (!response.ok) {
                throw new Error('Network response was not ok')
            }
            return response.json()

        }, {
        retryDelay: attemptIndex => Math.min(30 * 1000 * (2 ** attemptIndex), 30 * 60 * 1000),
        initialData: [
            {
                id: 827758,
                name: "grote_binnenpiste",
                errors: null
            },
            {
                id: 827759,
                name: "buitenpiste",
                errors: null
            },
            {
                id: 827757,
                name: "kleine_binnenpiste",
                errors: null
            }
        ]
    })

}

