import './App.css';
import logo from './stal_ten_legenbos.png';
import { useReservationsQuery, useResourcesQuery } from './api/supersaas'
import { Calendar, momentLocalizer, Views } from 'react-big-calendar'
import moment from 'moment/min/moment-with-locales'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import { NextSixDays } from './NextSixDays'
import QRCode from 'qrcode.react';
import { useEffect } from 'react';


moment.locale('nl');
const localizer = momentLocalizer(moment)


const BACKGROUND_COLOR = {
  'grote binnenpiste': '#8997A5',
  'kleine binnenpiste': '#C4A883',
  'buitenpiste': '#59BFB3',
};


const BORDER_COLOR = {
  'grote binnenpiste': '#4E5D6C',
  'kleine binnenpiste': '#8D6F47',
  'buitenpiste': '#1B887A',
};


function reservatiePropsGetter(reservatie) {
  return {
    style: {
      backgroundColor: BACKGROUND_COLOR[reservatie.resName],
      backgroundImage: reservatie.type === "springles" ? `url("/Icon_springen.svg")`: '',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: "right 5px bottom 5px",
      border: `1px solid ${BORDER_COLOR[reservatie.resName]}`,
    }
  }


}

function App() {

  const { isLoading, data: reservations, dataUpdatedAt, isSuccess } = useReservationsQuery();
  const { data: resources } = useResourcesQuery();

  useEffect(() => {

    setTimeout(() => {
      window.location.reload()
    }, 3600000)


  }, []);

  return (<>
    <div className="App" >
      <div style={{ width: '100%' }}>
        <header>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <img src={logo} alt="" style={{ height: 120, width: 'auto' }} />
          </div>

          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <h1>Agenda</h1>
          </div>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <span style={{ fontSize: '1.5em' }}>Maak een reservatie op: </span>
            <QRCode style={{ width: 120, height: 120, marginLeft: 24 }}
              bgColor="rgba(219, 215, 203, 0.38)" fgColor="rgb(51, 51, 51)"
              renderAs="svg"
              value="https://www.supersaas.nl/schedule/staltenlegenbos/Lessen"
            />
          </div>

        </header>


        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div style={{ flex: '1 0 auto', marginRight: 24 }}>
            <h2>Vandaag</h2>
            <div style={{ height: 820 }}><Calendar localizer={localizer}
              events={reservations}
              startAccessor="start"
              endAccessor="end"
              titleAccessor="title"
              resourceAccessor="resourceId"
              showMultiDayTimes={false}
              eventPropGetter={reservatiePropsGetter}
              resources={resources}
              resourceTitleAccessor={(resource) => resource.name?.replace('_', ' ')}
              resourceIdAccessor="id"

              defaultView={Views.DAY}
              min={moment("2000-01-01T07:00").toDate()} max={moment("2000-01-01T21:59").toDate()}
              toolbar={false}

            /></div>


          </div>
          <div style={{ flex: '3 0 auto' }}>
            <h2>Komende dagen</h2>
            <div style={{ height: 820 }}><Calendar localizer={localizer}
              events={reservations}
              startAccessor="start"
              endAccessor="end"
              titleAccessor="title"
              resourceAccessor="resourceId"
              showMultiDayTimes={false}

              eventPropGetter={reservatiePropsGetter}
              // resources={resources}
              resourceTitleAccessor={(resource) => resource.name?.replace('_', ' ')}
              resourceIdAccessor="id"

              defaultView={Views.WEEK}
              views={{ week: NextSixDays }}
              defaultDate={moment().add(1, 'day').startOf('day').toDate()}
              min={moment("2000-01-01T07:00").toDate()} max={moment("2000-01-01T21:59").toDate()}
              toolbar={false}

            /></div>
          </div>
        </div>
      </div>

    </div >
    {isLoading ? <div className="loading" key={`progress-${dataUpdatedAt}`}> </div> : null}
    {isSuccess ? <div className="progress" key={`progress-${dataUpdatedAt}`}> </div> : null}
  </>
  );
}

export default App;
