import React from 'react'

import * as dates from 'date-arithmetic'
import { Navigate } from 'react-big-calendar'
import TimeGrid from 'react-big-calendar/lib/TimeGrid'

export class NextSixDays extends React.Component {
    render() {
        let { date } = this.props
        let range = NextSixDays.range(date)

        return <TimeGrid {...this.props} range={range} eventOffset={15} />
    }
}

NextSixDays.range = date => {
    let start = dates.add(date, 0, 'day')
    let end = dates.add(start, 5, 'day')

    let current = start
    let range = []

    while (dates.lte(current, end, 'day')) {
        range.push(current)
        current = dates.add(current, 1, 'day')
    }

    return range
}

NextSixDays.navigate = (date, action) => {
    switch (action) {
        case Navigate.PREVIOUS:
            return dates.add(date, -6, 'day')

        case Navigate.NEXT:
            return dates.add(date, 6, 'day')

        default:
            return date
    }
}

NextSixDays.title = date => {
    return `Next 6 days`
}

